<template>
  <div class="_wrap">
    <div class="_banner">
      <!-- <img src="@/assets/image/takeCare-img/baoguan_banner.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
      <img
        :src="$IMG_URL + $store.state.webData.top_image.collection_list_image"
        alt="八路军驻洛办事处纪念馆-官方网站"
      />
    </div>

    <div class="_content">
      <div class="_auto">
        <div class="_gczp">
          <h4>馆藏珍品</h4>
          <p class="_ppp" v-html="collection_content"></p>
        </div>

        <div class="_cplb" id="cplb">
          <h4 class="_h4">藏品类别</h4>

          <div class="_types">
            <el-form
              :model="queryParams"
              ref="queryForm"
              size="small"
              :inline="true"
            >
              <el-form-item label="文物年代" prop="year_date">
                <el-select
                  v-model="queryParams.year_date"
                  placeholder="请选择文物年代"
                  clearable
                  style="width: 200px"
                >
                  <el-option
                    v-for="(item, index) in years"
                    :key="index"
                    :label="item.year_date"
                    :value="item.year_date"
                   
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="文物质地" prop="texture_id"   class="_t_se">
                <el-select
                  v-model="queryParams.texture_id"
                  placeholder="请选择文物质地"
                  clearable
                  style="width: 200px"
                 
                >
                  <el-option
                    v-for="(item, index) in textures"
                    :key="index"
                    :label="item.texture"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="关键词" prop="keywords">
                <el-input
                  v-model="queryParams.keywords"
                  placeholder="请输入关键词"
                  clearable
                  @keyup.enter.native="handleQuery"
                  style="width: 200px"
                />
              </el-form-item>
              <el-form-item>
                <el-button class="_btn" size="mini" @click="handleQuery"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div>

          <div class="_list">
            <div
              class="_li"
              v-for="(item, index) in collectData"
              :key="index"
              @click="goDetail(item)"
            >
              <div class="_top">
                <img
                  class="_img"
                  :src="$IMG_URL + item.image"
                  alt="八路军驻洛办事处纪念馆-官方网站"
                />
                <img
                  class="_dd"
                  src="../../assets/image/takeCare-img/3d-icon.png"
                  alt="八路军驻洛办事处纪念馆-官方网站"
                />
              </div>
              <div class="_bo">
                <h4>{{ item.collection_name }}</h4>
              </div>
            </div>
          </div>

          <div class="_more">
            <span @click="lookMore">查看更多</span>
          </div>
        </div>
      </div>

      <div class="_cpzj" id="cpzj">
        <div class="_cp_nr">
          <h4>
            <img
              src="../../assets/image/takeCare-img/baoguan_bg_zhengji_left.png"
              alt="八路军驻洛办事处纪念馆-官方网站"
            />
            <span>藏品征集</span>
            <img
              src="../../assets/image/takeCare-img/baoguan_bg_zhengji_right.png"
              alt="八路军驻洛办事处纪念馆-官方网站"
            />
          </h4>

          <div class="_list">
            <div
              :class="{ _li: true }"
              v-for="(item, index) in cpList"
              :key="index"
              @click="goZj(item)"
              @mouseenter="mouseImg(item, index)"
              @mouseleave="leaImg(item, index)"
            >
              <img
                :src="item.icon"
                alt="八路军驻洛办事处纪念馆-官方网站"
                class="_img"
              />

              <p>{{ item.title }}</p>
              <img
                v-show="index == current"
                src="@/assets/image/takeCare-img/icon_jiantou.png"
                alt="八路军驻洛办事处纪念馆-官方网站"
                class="_jt"
              />
            </div>
          </div>

          <div class="_dw">
            <img
              src="../../assets/image/takeCare-img/zhanlan_bg_zhengji_yinzhang.png"
              alt="八路军驻洛办事处纪念馆-官方网站"
            />
          </div>
        </div>
      </div>

      <!-- <div class="_cpzm" id="cpzm">
        <div class="_cpzm_auto">
          <h4>藏品总目</h4>
          <div class="_th">
            <p class="_th_name">名称</p>
            <div>
              <p>材质</p>
              <p class="_shu">|</p>
              <p>年代</p>
            </div>
          </div>
          <div class="_list">

            <div class="_li" v-for="(item, index) in headList" :key="index">
              <p class="_name">{{ item.relic_name }}</p>
              <div class="_r">
                <p class="_cz">{{ item.quality_three }}</p>
                <p class="_time _impact">{{ item.collect_year }}</p>
              </div>
            </div>


          </div>


          <div class="_takePage">

            <pagination v-show="total1 > 0" :total="total1" :page.sync="queryParams1.page"
              :limit.sync="queryParams1.page_num" @pagination="getList1" />
          </div>

        </div>
      </div> -->

      <mainFooter ref="mainFooterRef"></mainFooter>
    </div>
  </div>
</template>

<script>
import {
  collectionListOther,
  collectionList,
  collectionHeadList,
  collectionCollectingList,
} from "@/api/getData";
export default {
  data() {
    return {
      wbTitle: "八路军驻洛办事处纪念馆-保管",
      keywords: "八路军驻洛办事处纪念馆,洛八办",
      desc: "八路军驻洛办事处纪念馆",
      current: -1,
      title: "",
      obj: {},
      collcetTotal: 0,
      collection_content: "",
      collection_collecting: "",
      years: [],
      textures: [],
      headList: [],
      collectData: [],
      cpList: [],
      total: 100,
      queryParams: {
        page: 1,
        page_num: 12,
        year_date: "",
        texture_id: "",
        keywords: "",
      },
      total1: 0,
      queryParams1: {
        page: 1,
        page_num: 13,
      },
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: this.wbTitle,
      // meta: [
      //   { name: "keywords", content: this.keywords },
      //   { name: "description", content: this.desc },
      // ]
    };
  },

  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let title = to.query.tit;
      this.title = title;
      for (let i in this.obj) {
        console.log(i);
        if (this.title == i) {
          this.$scrollToSection(this.obj[i]);
          return;
        }
      }
    },
  },
  created() {},
  async mounted() {
    await this.getOther();
    await this.getList();
    await this.getList1();
    await this.collectionCollectingList();
    this.jumpId();
  },
  methods: {
    jumpId() {
      const title = this.$route.query.tit;
      console.log(title);
      const obj = {
        藏品类别: "cplb",
        征集: "cpzj",
        藏品总目: "cpzm",
        馆藏珍品: "cplb",
      };
      this.obj = obj;

      for (let i in obj) {
        console.log(i);
        if (title == i) {
          this.$scrollToSection(obj[i]);
          return;
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // 平滑滚动
          });
        }
      }
    },

    goZj(item) {
      let url = location.origin;
      console.log(url);
      window.open(`${url}/collectionDetail?id=${item.id}`);
    },
    mouseImg(item, index) {
      item.icon = require(`@/assets/image/takeCare-img/${index}-is.png`);
      this.current = index;
    },
    leaImg(item, index) {
      item.icon = require(`@/assets/image/takeCare-img/${index}.png`);
      this.current = -1;
    },
    // 获取其他数据
    async getOther() {
      await collectionListOther().then((res) => {
        console.log(res);
        this.textures = res.data.textures;
        this.years = res.data.years;
        this.collection_content = res.data.collection_content;
        this.collection_collecting = res.data.collection_collecting;
      });
    },
    // 获取藏品征集列表
    async collectionCollectingList() {
      await collectionCollectingList().then((res) => {
        // console.log(res)
        this.cpList = res.data;
        for (let i in this.cpList) {
          let a = require(`@/assets/image/takeCare-img/${[i]}.png`);
          this.$set(this.cpList[i], "icon", a);
        }
      });
    },
    handleQuery() {
      console.log(this.queryParams);
      this.queryParams.page = 1;
      this.collectData = [];
      this.getList();
    },
    async getList() {
      await collectionList(this.queryParams).then((res) => {
        console.log(res);
        this.collcetTotal = res.data.total;
        this.collectData = [...this.collectData, ...res.data.data];
      });
    },
    lookMore() {
      if (this.collectData.length >= this.collcetTotal) {
        return this.$message.warning("没有更多了");
      }
      this.queryParams.page += 1;
      this.getList();
    },
    goDetail(item) {
      console.log(item);

      let url = location.origin;
      console.log(url);
      window.open(`${url}/takeCareDetail?id=${item.id}`);
    },
    goDetail1() {
      let url = location.origin;
      console.log(url);
      window.open(`${url}/collectionDetail`);
    },
    async getList1() {
      await collectionHeadList(this.queryParams1).then((res) => {
        console.log(res);
        this.headList = res.data.data;
        this.total1 = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

@mixin ba_cont {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #831313;
  transform: rotate(45deg);
}

._wrap {
  width: 100%;
  overflow: hidden;
  // background-color: #FDFAF6;
}

._banner {
  width: 100%;

  height: 1186px;
  // background-color: #FDFAF6;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

._content {
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 350px;

  ._auto {
    width: 75%;
    margin: 0 auto;

    ._gczp {
      h4 {
        font-size: 60px;
        color: white;
        margin-bottom: 26px;
      }

      p {
        color: white;
        font-size: 20px;
        letter-spacing: 1px;
        width: 84%;
      }
    }

    ._cplb {
      margin-top: 86px;

      ._h4 {
        font-size: 36px;
        color: white;
        display: flex;
        align-items: center;
        margin-bottom: 36px;
      }

      ._h4::before {
        @include ba_cont;
        background-color: white;
        margin-right: 20px;
      }
    }

    ._types {
      margin-bottom: 25px;
    }

    ._list {
      display: flex;
      flex-wrap: wrap;
      min-height: 310px;
      // justify-content: space-between;

      ._li:hover ._bo h4 {
        color: $hover-color;
      }

      ._li {
        width: 23.65%;
        cursor: pointer;

        margin-right: 1.8%;
        margin-bottom: 60px;

        &:nth-child(4n) {
          margin-right: 0;
        }

        ._top {
          width: 100%;
          height: 192px;
          overflow: hidden;
          position: relative;
          ._img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.5s;
          }

          ._dd {
            position: absolute;
            right: 0;
            top: 0;
          }

          ._img:hover {
            transform: scale(1.1);
          }
        }

        ._bo {
          background-color: #fff;
          height: 57px;
          overflow: hidden;

          h4 {
            background-image: url("../../assets/image/takeCare-img/baoguan_bg_cangpin.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 95%;
            height: 47px;
            margin: 5px auto 0;
            text-align: center;
            font-size: 18px;
            line-height: 47px;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    ._more:hover span {
      color: $hover-color;
    }

    ._more {
      text-align: center;

      // margin-top: 10px;
      span {
        text-align: center;
        font-size: 18px;
        padding: 15px 44px;
        border: 1px solid #c4c4c4;
        color: #666;
        cursor: pointer;
      }
    }
  }

  ._cpzj {
    width: 100%;
    height: 358px;
    margin-top: 70px;
    background-image: url("../../assets/image/takeCare-img/zhanlan_bg_zhengji.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // overflow: hidden;
    position: relative;
    margin-bottom: 84px;

    ._cp_nr {
      overflow: hidden;
      height: 100%;

      h4 {
        text-align: center;
        margin-top: 66px;

        img {
          vertical-align: sub;
        }

        span {
          font-size: 36px;
          color: white;
          margin: 0 14px;
        }
      }

      ._list {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        // margin: 0 auto;
        margin-top: 50px;
        margin-left: 13.5%;

        ._li {
          width: 33%;

          color: #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          // margin-right: 2%;
          margin-bottom: 30px;

          p {
            font-size: 20px;
            margin-left: 16px;
          }

          ._img {
            margin-left: 15%;
          }

          ._jt {
            margin-left: 20px;
          }

          &:nth-child(3n) {
            // margin-right: 0;
            // margin-left: 10px;
            ._img {
              margin-left: 0%;
            }
          }
        }

        ._li:nth-child(3) {
          position: relative;
          z-index: 1;
        }

        ._li:hover p {
          color: #ffe0c9;
        }
      }

      ._dw {
        position: absolute;
        bottom: -70px;
        right: 200px;
      }
    }
  }

  ._cpzm {
    ._cpzm_auto {
      margin: 0 auto;
      width: 75%;

      h4 {
        font-size: 36px;
        display: flex;
        align-items: center;
        margin-bottom: 57px;
        color: #333;
      }

      h4::before {
        @include ba_cont;
        margin-right: 20px;
      }

      ._th {
        display: flex;
        align-items: center;
        padding: 15px 163px 15px 43px;
        justify-content: space-between;
        border: 1px solid #b41e1e;
        color: #b41e1e;

        ._th_name {
          font-size: 20px;
          font-weight: bold;
        }

        > div {
          display: flex;
          align-items: center;

          p {
            font-size: 20px;
            font-weight: bold;
          }

          ._shu {
            margin: 0 53px;
          }
        }
      }

      ._list {
        ._li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px 163px 24px 43px;
          border-bottom: 1px solid #e4e4e4;
          // cursor: pointer;

          ._name {
            font-size: 20px;
            letter-spacing: 1px;
            color: #333;
          }

          ._r {
            display: flex;
            align-items: center;

            p {
              font-size: 20px;
              color: #333;
            }

            ._time {
              margin-left: 110px;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-form--inline .el-form-item__label {
  color: white;
  font-size: 18px;
  padding-right: 7px;
  line-height: 40px;
}

::v-deep .el-form--inline .el-form-item {
  margin-right: 54px;
}

::v-deep .el-input__inner {
  background: transparent !important;
  border-radius: inherit;
  color: white;
  height: 40px;
  line-height: 40px;
}

::v-deep .el-select .el-input__inner:focus {
  border-color: #c0c4cc;
}

::v-deep .el-input__suffix {
  height: 80%;
}

._takePage {
  text-align: center;
  margin: 60px 0 100px;
}

._btn {
  color: #b41e1e;
  margin-left: -56px;
  width: 110px;
  height: 40px;
}

::v-deep ._ppp p {
  @include respondTo("phone") {
    font-size: 16px !important;
    height: 250px;
    overflow-y: scroll;
  }
}

@include respondTo("phone") {
  ._banner {
    height: 800px;
  }
  ._content {
    top: 100px;
    ._auto {
      padding: 0 8px;

      ._gczp {
        h4 {
          font-size: 28px;
          margin-bottom: 20px;
        }
        p {
          width: 100%;
        }
      }

      ._cplb {
        margin-top: 20px;
        ._h4 {
          font-size: 28px;
          margin-bottom: 20px;
        }
        ._h4::before {
          margin-right: 10px;
        }
        ._types {
          ._btn{
            margin-left: 0;
          }
        }

        ._list{
          justify-content: space-between;
          ._li{
            width: 48%;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
        ._more{
          margin-top: 20px;
        }
      }

    }

    
    ._cpzj{
      margin-top: 40px;
        margin-bottom: 20px;

        ._cp_nr{
          h4{
            margin-top: 20px;
          }
          ._list{
            display: block;
            width: 100%;
            margin-top: 20px;
            margin-left: 0;
            position: relative;
            z-index: 5;
            ._li{
              width: 100%;
              margin-bottom: 20px;
              margin-left: 20px;
              ._img{
                margin-left: 0;
                width: 35px;
              }
              p{
                font-size: 16px;
              }
              ._jt{
                display: block !important;
                margin-left: 10px;
              }
            }
          }
        }
      }
  }
}

::v-deep .el-form--inline .el-form-item{
  @include respondTo("phone"){
      margin-right: 20px;

  }
}
::v-deep .el-form--inline .el-form-item__label {
  @include respondTo("phone"){
    font-size: 16px;
    display: none;

  }
}
.el-select{
  @include respondTo("phone"){
    width: 160px !important;
  }
}
._t_se {
  @include respondTo("phone"){
   margin-right: 0 !important;
  }
}
.el-input--small{
  @include respondTo("phone"){
    width: 160px !important;
  }
}
</style>